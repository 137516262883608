export enum Level {
    FATAL,
    ERROR,
    WARN,
    INFO,
    DEBUG,
    TRACE,
}

type consoleLogTypes = {
    [logType: string]: (...args: any[]) => void
}

const __console: consoleLogTypes = {
    info: (...args: any[]): void => {
        console.info(...args);
    },
    debug: (...args: any[]): void => {
        console.debug(...args);
    },
    warn: (...args: any[]): void => {
        console.warn(...args);
    },
    error: (...args: any[]): void => {
        console.error(...args);
    },
    trace: (...args: any[]): void => {
        console.trace(...args);
    }
  }


export const LogLevel = Level;

const dateFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit"
}

export interface LoggerFunctions {
    setLevel(level: Level): void; 
    log(message: string, ...args: any[]): void; 
    fatal(message: string, ...args: any[]): void; 
    error(message: string, ...args: any[]): void; 
    warn(message: string, ...args: any[]): void; 
    info(message: string, ...args: any[]): void; 
    debug(message: string, ...args: any[]): void; 
    trace(message: string, ...args: any[]): void; 
}

const LoggerSingleton = (() => {
    let logger: LoggerFunctions| undefined = undefined;

    function init() {
        const level = Level.DEBUG;

        const logToConsole = (message: string, level: Level, ...args: any[]) => {
            if (typeof console !== "undefined" && shouldLog(level)) {
              const logLevelName = Level[level].toLowerCase();
              const formattedMessage = `${new Date().toLocaleString()} [${logLevelName}] ${message}`;
              try {
                __console[logLevelName](formattedMessage, ...args);
              } catch (e) {
                console.info(formattedMessage, ...args);
              }
            }
          };  

        const shouldLog = (currentLevel: Level): boolean => {
            return currentLevel <= level;
        }

        return {
            setLevel(level: Level): void {
                level = level;
            },

            log(message: string, ...args: any[]): void {
                if (level >= 4) {
                    logToConsole(message, Level.DEBUG, ...args)
                }
            },

            fatal(message: string, ...args: any[]): void {
                logToConsole(message, Level.FATAL, ...args);
            },

            error(message: string, ...args: any[]): void {
                logToConsole(message, Level.ERROR, ...args);
            },

            warn(message: string, ...args: any[]): void {
                logToConsole(message, Level.WARN, ...args);
            },

            info(message: string, ...args: any[]): void {
                logToConsole(message, Level.INFO, ...args);
            },

            debug(message: string, ...args: any[]): void {
                logToConsole(message, Level.DEBUG, ...args);
            },

            trace(message: string, ...args: any[]): void {
                logToConsole(message, Level.TRACE, ...args);
            }
        }
    }

    return {
        getInstance: () => {
            if (logger === undefined) {
                logger = init()
            }

            return logger;
        }
    }
})()

const Logger = LoggerSingleton.getInstance()
export default Logger;
export { Logger }
